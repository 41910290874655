import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { EmptyStateService } from 'src/app/core/services/empty-state.service';
import { ProductsStoreService } from 'src/app/core/store/services/products-store.service';
import { CategoryCreateComponent } from 'src/app/shared/dialogs/category-create/category-create.component';
import { DialogResult } from 'src/app/shared/dialogs/models';

@Component({
  selector: 'craft-empty-categories-dialog',
  templateUrl: './empty-categories-dialog.component.html',
  styleUrls: ['./empty-categories-dialog.component.scss'],
})
export class EmptyCategoriesDialogComponent extends Destroyer implements OnInit {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private ess: EmptyStateService,
    private pss: ProductsStoreService,
    private dialogRef: MatDialogRef<EmptyCategoriesDialogComponent, DialogResult>,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.skip();
  }

  public create() {
    this.dialog
      .open(CategoryCreateComponent, {
        autoFocus: false,
        maxWidth: '100vw',
        disableClose: true,
      })
      .afterClosed();

    this.dialogRef.close({ done: true });
  }

  public imp() {
    this.dialogRef.close({ done: true });
    this.router.navigate(['settings', 'integrations']);
  }

  public skipAndClose() {
    this.skip();
    this.dialogRef.close({ done: true });
  }

  private skip() {
    this.pss.selectedId$.pipe(take(1)).subscribe((productId) => {
      if (productId) {
        this.ess.addProductToSkipped(productId);
      }
    });
  }
}
