import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { CompaniesUsersListRequest, CompaniesUsersListResponse, CompanyUser } from 'src/app/core/models/companies.models';
import { CompanyUsersBackendService } from 'src/app/core/services/backend/company-users-backend.service';
import {
  CompanyReqAddUserAction,
  CompanyReqAddUserErrorAction,
  CompanyReqAddUserSuccessAction,
  CompanyReqDelUserAction,
  CompanyReqDelUserErrorAction,
  CompanyReqDelUserSuccessAction,
  CompanyReqUsersListAction,
  CompanyReqUsersListErrorAction,
  CompanyReqUsersListSuccessAction,
  CompanyUsersClearAction,
} from 'src/app/core/store/actions/company-users';
import { getCompaniesSelector } from 'src/app/core/store/reducers/companies';
import { getCompanyUsersSelector } from 'src/app/core/store/reducers/company-users';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class CompaniesUsersStoreService {
  private readonly companiesMap$ = this.store$.pipe(select(getCompaniesSelector));
  public readonly users$ = this.store$.pipe(select(getCompanyUsersSelector));

  constructor(
    private store$: Store<CoreState>, //
    private cubs: CompanyUsersBackendService,
  ) {}

  public loadUsers(companyId: string, skip = 0, limit = 100): Observable<CompaniesUsersListResponse> {
    return this.companiesMap$.pipe(
      take(1), //
      map((companies) => companies[companyId]),
      filter((company) => !!company),
      mergeMap((company) => {
        const req: CompaniesUsersListRequest = { skip, limit, companyId, total: company.usersCount };
        this.store$.dispatch(new CompanyReqUsersListAction(req));

        return this.cubs.getUsersList(req).pipe(
          map((users) => {
            const res: CompaniesUsersListResponse = { ...req, users };
            this.store$.dispatch(new CompanyReqUsersListSuccessAction(res));
            return res;
          }),
          catchError((err: ErrorResponse) => {
            this.store$.dispatch(new CompanyReqUsersListErrorAction(err));
            return throwError(() => err);
          }),
        );
      }),
    );
  }

  public loadRegisteredUsers(): Observable<CompanyUser[]> {
    return this.cubs.getRegisteredUsersList();
  }

  public addUser(user: CompanyUser): Observable<CompanyUser> {
    this.store$.dispatch(new CompanyReqAddUserAction(user));

    return this.cubs.addUser(user).pipe(
      map((res) => {
        this.store$.dispatch(new CompanyReqAddUserSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CompanyReqAddUserErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public deleteUser(user: CompanyUser): Observable<void> {
    this.store$.dispatch(new CompanyReqDelUserAction(user));

    return this.cubs.deleteUser(user).pipe(
      map(() => {
        this.store$.dispatch(new CompanyReqDelUserSuccessAction(user));
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new CompanyReqDelUserErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public resendInvite(user: CompanyUser): Observable<void> {
    return this.cubs.resendInvite(user);
  }

  public clearUsersCache(companyId: string): void {
    this.store$.dispatch(new CompanyUsersClearAction(companyId));
  }
}
