<div class="empty-state-dialog">
  <h1 mat-dialog-title class="dialog-title">Jumpstart your portal</h1>

  <div mat-dialog-content="" class="dialog-content d-flex flex-column">
    <img src="assets/img/empty/empty-box.png" alt="" />
    <div class="actions">
      <button class="btn btn-text-blue btn-border btn-action" type="button" (click)="create()" mat-button="">
        {{ 'common.text14' | craftTerm }}
      </button>

      <button *craftPermissions="'Admin'" class="btn btn-blue btn-action" type="button" (click)="imp()" mat-button="">
        {{ 'common.text64' | craftTerm }}
      </button>
    </div>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-center">
    <button type="button" (click)="skipAndClose()" class="btn">Skip and go to portal</button>
  </div>
</div>
