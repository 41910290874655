import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Category, DBCategory } from 'src/app/core/models/categories.models';
import { PickRequired } from 'src/app/core/models/common.models';
import { CompanyInviteLink, DBCompany } from 'src/app/core/models/companies.models';
import { CraftItem } from 'src/app/core/models/craft.models';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import { DBIdea } from 'src/app/core/models/idea.models';
import { DBImportance } from 'src/app/core/models/importances.models';
import { DBInnerStatus } from 'src/app/core/models/inner-statuses.models';
import { Product } from 'src/app/core/models/products.models';
import { DBTag } from 'src/app/core/models/tags.models';
import { DBWorkflowStatus } from 'src/app/core/models/workflow-statuses.models';
import { InviteUserDialogComponent } from 'src/app/settings/pages/admin-users/invite-user-dialog/invite-user-dialog.component';
import { RolesHelperDialogComponent } from 'src/app/settings/pages/admin-users/roles-helper-dialog/roles-helper-dialog.component';
import { CompanyPermissionsDialogComponent } from 'src/app/settings/pages/companies/company-details/company-invite-links/company-permissions-dialog/company-permissions-dialog.component';
import { ChangeWorkspaceDialogComponent } from 'src/app/settings/pages/general/change-workspace-dialog/change-workspace-dialog.component';
import { CreateCustomDateFieldComponent } from 'src/app/shared/components/custom-fileds/custom-field-dialog/date-field/date-field.component';
import { CreateCustomMultiSelectFieldComponent } from 'src/app/shared/components/custom-fileds/custom-field-dialog/multi-select-field/multi-select.component';
import { CreateCustomNumberFieldComponent } from 'src/app/shared/components/custom-fileds/custom-field-dialog/number-field/number-field.component';
import { CreateCustomSingleSelectFieldComponent } from 'src/app/shared/components/custom-fileds/custom-field-dialog/single-select-field/single-select.component';
import { CreateCustomTextFieldComponent } from 'src/app/shared/components/custom-fileds/custom-field-dialog/text-field/text-field.component';
import { CustomFieldDialogResult } from 'src/app/shared/components/custom-fileds/custom-field-dialog/types';
import { DeleteCustomFieldDialogComponent } from 'src/app/shared/components/custom-fileds/delete-custom-field-dialog/delete-custom-field-dialog.component';
import { AlertDialogComponent, AlertDialogInput } from 'src/app/shared/dialogs/alert-dialog/alert-dialog.component';
import { AlertErrorDialogComponent } from 'src/app/shared/dialogs/alert-error-dialog/alert-error-dialog.component';
import { CancelUploadDialogComponent } from 'src/app/shared/dialogs/cancel-upload-dialog/cancel-upload-dialog.component';
import { CategoryCreateComponent } from 'src/app/shared/dialogs/category-create/category-create.component';
import { CategoryEditComponent } from 'src/app/shared/dialogs/category-edit/category-edit.component';
import { CategoryPrivacySettingsComponent } from 'src/app/shared/dialogs/category-privacy-settings/category-privacy-settings.component';
import { ChangePortalDomainAlertDialogComponent } from 'src/app/shared/dialogs/change-portal-domain-dialog/change-portal-domain-dialog.component';
import { CompanyUsersDialogComponent } from 'src/app/shared/dialogs/company-users-dialog/company-users-dialog.component';
import { CraftItemEditComponent, CraftItemEditDialogInput } from 'src/app/shared/dialogs/craft-item-edit/craft-item-edit.component';
import { DeleteAttachmentDialogComponent } from 'src/app/shared/dialogs/delete-attachment-dialog/delete-attachment-dialog.component';
import { DeleteCategoryDialogComponent, DeleteCategoryDialogInput } from 'src/app/shared/dialogs/delete-category-dialog/delete-category-dialog.component';
import { DeleteCommentDialogComponent } from 'src/app/shared/dialogs/delete-comment-dialog/delete-comment-dialog.component';
import { DeleteCompanyAdminDialogComponent } from 'src/app/shared/dialogs/delete-company-admin-dialog/delete-company-admin-dialog.component';
import { DeleteCompanyDialogComponent } from 'src/app/shared/dialogs/delete-company-dialog/delete-company-dialog.component';
import { DeleteCompanyInviteLinkDialogComponent } from 'src/app/shared/dialogs/delete-company-invite-link-dialog/delete-company-invite-link-dialog.component';
import { DeleteIdeaDialogComponent } from 'src/app/shared/dialogs/delete-idea-dialog/delete-idea-dialog.component';
import { DeleteImportanceDialogComponent } from 'src/app/shared/dialogs/delete-importance-dialog/delete-importance-dialog.component';
import { DeleteInnerStatusDialogComponent } from 'src/app/shared/dialogs/delete-inner-status-dialog/delete-inner-status-dialog.component';
import { DeleteNoteDialogComponent } from 'src/app/shared/dialogs/delete-note-dialog/delete-note-dialog.component';
import { DeleteTagDialogComponent } from 'src/app/shared/dialogs/delete-tag-dialog/delete-tag-dialog.component';
import { DeleteWorkflowStatusDialogComponent } from 'src/app/shared/dialogs/delete-workflow-status-dialog/delete-workflow-status-dialog.component';
import { EmptyCategoriesDialogComponent } from 'src/app/shared/dialogs/empty-categories-dialog/empty-categories-dialog.component';
import { CreateIdeaDialogInput, IdeaCreateDialogComponent } from 'src/app/shared/dialogs/idea-create/idea-create.component';
import { IdeaEditDialogComponent } from 'src/app/shared/dialogs/idea-edit/idea-edit.component';
import { DialogResult } from 'src/app/shared/dialogs/models';
import { NotificationAction, NotificationMessage, NotificationService } from 'src/app/shared/dialogs/notifications/notifications.service';
import {
  CraftItemDialogInput,
  CraftItemDialogResult,
  SelectCraftItemDialogComponent,
} from 'src/app/shared/dialogs/select-craft-item/select-craft-item-dialog/select-craft-item-dialog.component';
import { CreateTagsDialogInput, CreateTagsDialogResult, TagsCreateDialogComponent } from 'src/app/shared/dialogs/tags-create/tags-create.component';
import { DeleteFormDialogComponent } from './delete-form-dialog/delete-form-dialog.component';
import { WorkspaceConnectionWarningDialogComponent } from './workspace-connection-warning-dialog/workspace-connection-warning-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogManagerService {
  constructor(
    private dialog: MatDialog, //
    private notifyService: NotificationService,
  ) {}

  public showAlert(data: AlertDialogInput): Observable<DialogResult | undefined> {
    return this.dialog.open(AlertDialogComponent, { data }).afterClosed();
  }

  public showErrorAlert(): Observable<DialogResult | undefined> {
    return this.dialog.open(AlertErrorDialogComponent).afterClosed();
  }

  public showChangeDomain(): Observable<void> {
    return this.dialog
      .open(ChangePortalDomainAlertDialogComponent, {
        disableClose: true,
        closeOnNavigation: false,
      })
      .afterClosed();
  }

  public showDeleteImportance(imp: DBImportance): Observable<DBImportance | undefined> {
    return this.dialog.open(DeleteImportanceDialogComponent, { data: imp }).afterClosed();
  }

  public showDeleteInnerStatus(status: DBInnerStatus): Observable<DBInnerStatus | undefined> {
    return this.dialog.open(DeleteInnerStatusDialogComponent, { data: status }).afterClosed();
  }

  public showDeleteWorkflowStatus(status: DBWorkflowStatus): Observable<DBWorkflowStatus | undefined> {
    return this.dialog.open(DeleteWorkflowStatusDialogComponent, { data: status }).afterClosed();
  }

  public showIdeaCreate(data?: CreateIdeaDialogInput, config?: MatDialogConfig): Observable<void> {
    return this.dialog
      .open(IdeaCreateDialogComponent, {
        data,
        autoFocus: false,
        maxWidth: '100vw',
        disableClose: true,
        ...config,
      })
      .afterClosed();
  }

  public showIdeaEdit(idea: DBIdea, config?: MatDialogConfig): Observable<DBIdea | undefined> {
    return this.dialog
      .open(IdeaEditDialogComponent, {
        data: idea,
        autoFocus: false,
        maxWidth: '100vw',
        disableClose: true,
        ...config,
      })
      .afterClosed();
  }

  public showIdeaDelete(idea: DBIdea): Observable<DBIdea | undefined> {
    return this.dialog.open(DeleteIdeaDialogComponent, { data: idea }).afterClosed();
  }

  public showEmptyCategories(): Observable<DialogResult> {
    return this.dialog.open(EmptyCategoriesDialogComponent, { maxWidth: '100vw' }).afterClosed();
  }

  public showCategoryCreate(): Observable<Category | undefined> {
    return this.dialog
      .open(CategoryCreateComponent, {
        autoFocus: false,
        maxWidth: '100vw',
        disableClose: true,
      })
      .afterClosed();
  }

  public showCategoryEdit(category: DBCategory): Observable<DBCategory | undefined> {
    return this.dialog
      .open(CategoryEditComponent, {
        data: category,
        autoFocus: false,
        maxWidth: '100vw',
        disableClose: true,
      })
      .afterClosed();
  }

  public showCategoryDelete(data: DeleteCategoryDialogInput): Observable<void> {
    return this.dialog.open(DeleteCategoryDialogComponent, { data }).afterClosed();
  }

  public showCategoryPrivicySettings(category: Category, config?: MatDialogConfig): Observable<Category | undefined> {
    return this.dialog.open(CategoryPrivacySettingsComponent, { ...config, data: category }).afterClosed();
  }

  public showCompanyDelete(company: DBCompany): Observable<DBCompany | undefined> {
    return this.dialog.open(DeleteCompanyDialogComponent, { data: company }).afterClosed();
  }

  public showCancelUpload(): Observable<DialogResult | undefined> {
    return this.dialog.open(CancelUploadDialogComponent).afterClosed();
  }

  public showAttachmentDelete(): Observable<DialogResult | undefined> {
    return this.dialog.open(DeleteAttachmentDialogComponent).afterClosed();
  }

  public showNoteDelete(): Observable<DialogResult | undefined> {
    return this.dialog.open(DeleteNoteDialogComponent).afterClosed();
  }

  public showCommentDelete(): Observable<DialogResult | undefined> {
    return this.dialog.open(DeleteCommentDialogComponent).afterClosed();
  }

  public showCompanyAdminDelete(): Observable<DialogResult | undefined> {
    return this.dialog.open(DeleteCompanyAdminDialogComponent).afterClosed();
  }

  public showCustomFieldDelete(field: CustomField): Observable<DialogResult | undefined> {
    return this.dialog.open(DeleteCustomFieldDialogComponent, { data: field }).afterClosed();
  }

  public showChangeWorkspace(data: { oldWorkspace: Product; newWorkspace: Product }): Observable<DialogResult | undefined> {
    return this.dialog.open(ChangeWorkspaceDialogComponent, { data: data }).afterClosed();
  }

  public showInviteUserDialog(): Observable<DialogResult | undefined> {
    return this.dialog.open(InviteUserDialogComponent).afterClosed();
  }

  public showRolwsHelpDialog(): Observable<DialogResult | undefined> {
    return this.dialog.open(RolesHelperDialogComponent).afterClosed();
  }

  public showCustomFieldEdit(field: Partial<CustomField>): Observable<CustomFieldDialogResult | undefined> {
    let component: ComponentType<any> = CreateCustomTextFieldComponent;

    switch (field.type) {
      case 'DATE':
        component = CreateCustomDateFieldComponent;
        break;
      case 'NUMBER':
        component = CreateCustomNumberFieldComponent;
        break;
      case 'SINGLESELECT':
        component = CreateCustomSingleSelectFieldComponent;
        break;
      case 'MULTISELECT':
        component = CreateCustomMultiSelectFieldComponent;
        break;
    }

    return this.dialog.open(component, { data: field, disableClose: true }).afterClosed();
  }

  public showCompanyUsersList(companyId: string): Observable<void> {
    return this.dialog.open(CompanyUsersDialogComponent, { data: companyId }).afterClosed();
  }

  public showCompanyDeleteInviteLink(link: CompanyInviteLink): Observable<void> {
    return this.dialog.open(DeleteCompanyInviteLinkDialogComponent, { data: link }).afterClosed();
  }

  public showNotification(message: PickRequired<NotificationMessage, 'message'>): Observable<NotificationAction | undefined> {
    return this.notifyService.show(message);
  }

  public showSelectCraftParentDialog(data: CraftItemDialogInput): Observable<{ idea?: DBIdea; item?: CraftItem }> {
    return this.dialog
      .open<SelectCraftItemDialogComponent, CraftItemDialogInput, CraftItemDialogResult>(SelectCraftItemDialogComponent, { data })
      .afterClosed()
      .pipe(
        switchMap((res) => {
          if (res?.editItem && res.item && res.promoteItemType) {
            return this.dialog
              .open<CraftItemEditComponent, CraftItemEditDialogInput, DBIdea | undefined>(CraftItemEditComponent, {
                data: {
                  idea: data.idea,
                  promoteItemType: res.promoteItemType,
                },
              })
              .afterClosed()
              .pipe(map((idea) => ({ item: res.item, idea })));
          }
          return of({ item: res?.item, idea: data.idea });
        }),
      );
  }

  public showTagsCreate(data: CreateTagsDialogInput): Observable<CreateTagsDialogResult | undefined> {
    return this.dialog.open<TagsCreateDialogComponent, CreateTagsDialogInput, CreateTagsDialogResult>(TagsCreateDialogComponent, { data }).afterClosed();
  }

  public showTagDelete(tag: DBTag): Observable<DBTag | undefined> {
    return this.dialog.open(DeleteTagDialogComponent, { data: tag }).afterClosed();
  }

  public showFormDelete(formId: string): Observable<string | undefined> {
    return this.dialog.open(DeleteFormDialogComponent, { data: formId }).afterClosed();
  }

  public showCompanyPermissions(): Observable<void> {
    return this.dialog.open(CompanyPermissionsDialogComponent).afterClosed();
  }

  public showWorkspaceConnectionWarning(data: any): Observable<boolean> {
    return this.dialog.open(WorkspaceConnectionWarningDialogComponent, { data }).afterClosed();
  }
}
